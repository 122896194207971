import React, { useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller } from "swiper";

import styled from "styled-components";
import { Maybe, SanityProduct } from "@graphql-types";
import { AbsoluteWrapper, Container } from "@util/standard";
import { Eurostile_BQ, colors, colorsRGB, mediaQuery } from "@util/constants";
import { CircleArrow } from "@util/assets";

//@ts-ignore
import xShot00 from "@static/assets/x-shot00.png";
//@ts-ignore
import xShot01 from "@static/assets/x-shot01.png";
//@ts-ignore
import xShot02 from "@static/assets/x-shot02.png";
//@ts-ignore
import xShot03 from "@static/assets/x-shot03.png";
//@ts-ignore
import xShot04 from "@static/assets/x-shot04.png";
//@ts-ignore
import xShotMini00 from "@static/assets/x-shotmini00.png";
//@ts-ignore
import xShotMini01 from "@static/assets/x-shotmini01.png";
//@ts-ignore
import xShotMini02 from "@static/assets/x-shotmini02.png";
//@ts-ignore
import xShotMini03 from "@static/assets/x-shotmini03.png";
//@ts-ignore
import xShotMini04 from "@static/assets/x-shotmini04.png";
//
interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | undefined | any;
}

const StyledSwiper = styled(Swiper)`
  width: 100%;
  .swiper-slide {
    height: 230px;
    background: ${colorsRGB.charcoal(0.1)};
    opacity: 0.5;
    cursor: pointer;
    ${mediaQuery.largeDown} {
      height: 150px;
    }
  }
  .swiper-slide-active {
    height: 230px;
    opacity: 1;
    background: ${colors.white};

    ${mediaQuery.largeDown} {
      height: 150px;
    }
  }
`;
const NavWrapper = styled(AbsoluteWrapper)`
  display: flex;
  filter: invert(1);
  cursor: pointer;
  z-index: 3;
  top: 50%;
  width: 100%;
  padding: 0 20px;
  position: static;
  transform: translateY(41px);
  width: 30%;
  margin: 0px auto 70px auto;
  justify-content: space-between;

  @media (max-width: 768px) {
    position: static;
    transform: translateY(65%);
    width: 30%;
    padding: 0 10px;
    margin: 0px auto 70px auto;
    justify-content: space-between;
  }
  @media (max-width: 576px) {
    padding: 0;
  }
`;

const SlideContentWrapper = styled.div`
  margin: 0 auto 0 auto;
  text-align: center;

  .image-text-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    height: 750px;
    @media only screen and (max-width: 991px) {
      height: 700px;
    }
    @media only screen and (max-width: 768px) {
      height: 600px;
    }
    @media only screen and (max-width: 576px) {
      height: 400px;
    }
  }
  .slider-background-text {
    font-family: "Formula Condensed Bold", sans-serif;
    font-weight: 700;
    font-size: 500px;
    line-height: 110%;
    color: #D9D9D9;
    text-shadow: -1px -1px 0 #D9D9D9, 1px -1px 0 #D9D9D9, -1px 1px 0 #D9D9D9, 1px 1px 0 #D9D9D9;
    color: white;
    letter-spacing: 0.035em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    @media only screen and (max-width: 1520px) {
      font-size: 450px;
    }
    @media only screen and (max-width: 1420px) {
      font-size: 420px;
    }
    @media only screen and (max-width: 1350px) {
      font-size: 390px;
    }
    @media only screen and (max-width: 1250px) {
      font-size: 360px;
    }
    @media only screen and (max-width: 1150px) {
      font-size: 330px;
    }
    @media only screen and (max-width: 1050px) {
      font-size: 300px;

    }
    @media only screen and (max-width: 970px) {
      font-size: 280px;
    }
    @media only screen and (max-width: 840px) {
      font-size: 230px;
    }
    @media only screen and (max-width: 680px) {
      font-size: 185px;
     }
    @media only screen and (max-width: 576px) {
      font-size: 150px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 130px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 110px;
    }
    @media only screen and (max-width: 350px) {
      font-size: 100px;
    }
  }
  .slider-content-container {
    display: flex;
    margin: 60px 50px;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin: 40px 50px;
    }
    @media only screen and (max-width: 768px) {
      margin: 30px 25px;
    }
 
  .slider-content-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      align-items: flex-start;
    }
    .slider-content-header {
      font-family:${Eurostile_BQ};
      font-size: 72px;
      font-weight: 500;
      line-height: 65px;
      letter-spacing: -0.05em;
      text-align: left;
      margin-bottom: 60px;
      @media only screen and (max-width: 1280px) {
        font-size: 60px;
        margin-bottom: 0;
        line-height: 1;
      }
      @media only screen and (max-width: 576px) {
        font-size: 48px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 36px;
      }
    }
    .slider-content-points {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px 15%;
      text-align: left;
      list-style: disc;
      @media only screen and (max-width: 1024px) {
        margin: 30px 0;
        width: 100%;
      }
      @media only screen and (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px 0;
      }
      li {
        font-size: 16px;
      }
    }
  }
  .slider-content-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    .slider-content {
      text-align: left;
      font-size: 16px;
    }
    .slider-content-button {
      background-color: ${colors.white};
      font-size: 18px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.05em;
      text-align: center;
      margin-top: 46px;
      @media only screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
    .background-btn {
      background-color: ${colors.black};
      border-radius: 68px;
      color: ${colors.white};
      cursor: pointer;
      padding: 12px 28px;
      margin-right: 32px;
      @media only screen and (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    .border-btn {
      border: 1px solid ${colors.black};
      border-radius: 100px;
      color: ${colors.black};
      cursor: pointer;
      background-color: ${colors.white};
      padding: 12px 28px;
    }
  }
`;
const SecondarySlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SliderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 50px;
  @media (max-width: 567px) {
    right: 30px;
  }
`;

function InsanitySlider({ data }: Props) {
  const slideData = [
    {
      id: 0,
      image: xShot00,
      smallImage: xShotMini00,
      backgroundText: "MANIC",
      sliderText: "MANIC",
      urlText: "manic",
      buttonLink: "/where-to-buy/?brand=x-shot",
      content: `Insane combos, ammo and action with XSHOT Insanity! Jump straight into action with the XSHOT Insanity Manic Blaster. With a single dart capacity and dart storage for an additional 46 darts, you'll never be left empty mid battle. The easy to reload mechanism means you’re always be prepared and ready to fire. The Manic can hit targets up to 27m / 90 feet away. XSHOT darts include Air Pocket Technology for a faster, further, and more accurate blast. Combine the Manic with other Insanity blasters to make the most insane blaster!`,
      contentPoints: [
        "24 Round Dart Capacity",
        "Air Pocket Dart Technology",
        "27m/90 Feet Firing Distance",
        "Combinable/Attachable",
      ],
    },
    {
      id: 1,
      image: xShot01,
      smallImage: xShotMini01,
      backgroundText: "BERZERKO",
      sliderText: "BERZERKO",
      urlText: "berzerko",
      buttonLink: "/where-to-buy/?brand=x-shot",
      content: `Insane combos, ammo and action with XSHOT Insanity! The XSHOT Insanity Berzerko packs enough power to take on targets 27m / 90 feet away! This blaster blows away the competition with a rapid-fire of 8 darts in seconds using the rotating barrel, plus an insane amount of dart storage to hold an additional 66 darts to keep you locked and loaded in battle. Use the bolt action priming mechanism to strike the competition before they see you coming. Combine the Berzerko with other Insanity blasters to make the most insane blaster!`,
      contentPoints: [
        "48 Round Dart Capacity",
        "Air Pocket Dart Technology",
        "27m/90 Feet Firing Distance",
        "Combinable/Attachable",
      ],
    },
    {
      id: 2,
      image: xShot02,
      smallImage: xShotMini02,
      backgroundText: "MEGAMAD",
      sliderText: "MAD MEGA BARREL",
      urlText: "mega-mad",
      buttonLink: "/where-to-buy/?brand=x-shot",
      content: `Insane combos, ammo and action with XSHOT Insanity! The XSHOT Insanity Mad Mega Barrel is so big it's Insane! Load up its 72-dart capacity barrel to take out multiple targets from up to 27m / 90 feet away with total accuracy and power! Plus it comes with an insane amount of dart storage to hold an additional 66 darts to keep you locked and loaded in battle. XSHOT darts include Air Pocket Technology for a faster, further, and more accurate blast. Combine the Mad Mega Barrel with other Insanity blasters to make the most insane blaster!`,
      contentPoints: [
        "72 Round Dart Capacity",
        "Air Pocket Dart Technology",
        "27m/90 Feet Firing Distance",
        "Combinable/Attachable",
      ],
    },
    {
      id: 3,
      image: xShot03,
      smallImage: xShotMini03,
      backgroundText: "RAGEFIRE",
      urlText: "ragefire",
      sliderText: "RAGEFIRE",
      buttonLink: "/where-to-buy/?brand=x-shot",
      content: `Insane combos, ammo and action with XSHOT Insanity! The XSHOT Insanity Motorized Rage Fire is the biggest and most insane blaster in the Insanity Range! The Motorized Rage Fire is so big it comes equipped with a tripod. Load up its 40 dart auto feeding belt to take out multiple targets from up to 27m / 90 feet away in seconds! Plus it comes with an insane amount of accessories and dart storage to hold an additional 46 darts to keep you locked and loaded in battle. Combine the Motorized Rage Fire with other Insanity blasters to make the most insane blaster!`,
      contentPoints: [
        "72 Round Dart Capacity",
        "Air Pocket Dart Technology",
        "27m/90 Feet Firing Distance",
        "Combinable/Attachable",
      ],
    },
    {
      id: 4,
      image: xShot04,
      smallImage: xShotMini04,
      backgroundText: "MICRO",
      urlText: "micro",
      sliderText: "MICRO",
      buttonLink: "/where-to-buy/?brand=x-shot",
      content: `Get insane combos, extra ammo and all the action with XSHOT Insanity Micro Blaster! Blitz your opponents with its 24 foam dart capacity. And with two Side Modular Dart Storage Units, the battle doesn't end there. An easy reload mechanism means you're always primed for action, while next level firing power ensures you can hit opponents up to 30m / 98 feet away. Bring the latest in foam dart technology to your next blaster battle!`,
      contentPoints: [
        "24 Round Dart Capacity",
        "Air Pocket Dart Technology",
        "30m / 98 Feet Firing Distance",
        "Combinable/Attachable",
      ],
    },
  ];
  const [controller, setController] = useState<SwiperCore>();
  const [secondaryController, setSecondaryController] = useState<SwiperCore>();
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);

  const slideChanged = useRef(false);
  const swiperRef = useRef(null);
  useEffect(() => {
    const urlFragment = window.location.hash.substring(1);
    const slideIndex = slideData.findIndex(slide => slide.urlText === urlFragment);

    if (slideIndex !== -1) {
      setInitialSlideIndex(slideIndex);
    }
  }, []);

  const handleSlideChange = (swiper: any) => {
    if (!slideChanged.current) {
      slideChanged.current = true;
      return;
    }

    const activeSlide = slideData[swiper.realIndex];
    const urlText = activeSlide.urlText;
    navigate(`/brands/x-shot/insanity#${urlText}`);
  };
  const breakpoints = {
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    992: {
      slidesPerView: 4,
    },
  };
  const handleButtonClick = () => {
    const videoElement = document.getElementById("insanity-video");
    videoElement?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Container flexDirection="column" width="100%">
        <Container width="100%">
          {controller && (
            <StyledSwiper
              modules={[Controller]}
              onSwiper={setSecondaryController}
              controller={{ control: controller }}
              slidesPerView={2}
              loop
              spaceBetween={5}
              loopedSlides={slideData.length}
              initialSlide={initialSlideIndex}
              onClick={e => controller.slideTo(e.clickedIndex)}
              breakpoints={breakpoints}
            >
              {slideData.map((product, index) => {
                return (
                  <SwiperSlide key={`swiper_2_${product.id}${index}`}>
                    <SecondarySlideWrapper>
                      {<img src={product.smallImage} />}
                    </SecondarySlideWrapper>
                  </SwiperSlide>
                );
              })}
            </StyledSwiper>
          )}
        </Container>
        <Container position="relative">
          <Container>
            <SliderButtonWrapper>
              <NavWrapper onClick={() => controller?.slidePrev()}>
                <CircleArrow />
              </NavWrapper>
              <NavWrapper onClick={() => controller?.slideNext()}>
                <CircleArrow flip />
              </NavWrapper>
            </SliderButtonWrapper>
          </Container>
          <Swiper
            //@ts-ignore
            ref={swiperRef}
            modules={[Controller]}
            onSwiper={setController}
            controller={{ control: secondaryController }}
            loop
            autoplay
            slidesPerView={1}
            loopedSlides={slideData.length}
            initialSlide={initialSlideIndex}
            onSlideChange={handleSlideChange}
          >
            {slideData.map(product => {
              return (
                <SwiperSlide key={`swiper_1_${product.id}`}>
                  <SlideContentWrapper>
                    <div className="image-text-wrapper">
                      <div className="slider-background-text">{product.backgroundText}</div>
                      <img src={product.image} alt={product.backgroundText} />
                    </div>
                    <div className="slider-content-container">
                      <div className="slider-content-left">
                        <div className="slider-content-header">{product.sliderText}</div>
                        <div className="slider-content-points">
                          {product.contentPoints.map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </div>
                      </div>
                      <div className="slider-content-right">
                        <div className="slider-content">{product.content}</div>
                        <div className="slider-content-button">
                          <button className="background-btn" onClick={handleButtonClick}>
                            How to Build
                          </button>
                          <Link to={product.buttonLink}>
                            <button className="border-btn">Where to buy </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SlideContentWrapper>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      </Container>
    </>
  );
}

export default InsanitySlider;
